import {
  type AuthPayload,
  type AuthRequest,
  type UserWithPermissions,
  type User,
  type OMSAuthResponse,
  P2Role,
  type RecordEntityId,
  MenuRoles,
} from '@centric-os/types';
import { defineStore, type StateTree } from 'pinia';
import type { RouteLocationNormalized } from 'vue-router';
import { ZendeskWidget, permissionMatch, zendesk } from '../../../helpers';
import type {
  PostUserAuthResponse,
  PostUserOauthResponse,
  GetUserAuthResponse,
  User as CDLUserProfile,
  auth,
  PostUserResetPasswordResponse,
  PostUserResetPasswordTokenResponse,
} from '@compassdigital/sdk.typescript/interface/user';
import type { OptionalPick } from '@centric-os/types/generics';
import { useSiteStore, useScannerStore } from '@centric-os/stores';
import { compact, filter, includes, isEmpty, map } from 'lodash';
import CDLID from '@compassdigital/id';

interface State extends StateTree {
  intendedRoute: RouteLocationNormalized;
  user: User;
  cdlUser: UserWithPermissions;
  usedSSO: boolean;
  ssoRedirectError: boolean;
  cdlRealm: string;
  auth: {
    access?: auth['access'];
    refresh?: auth['refresh'];
    oauth?: OMSAuthResponse['oauthaccess'];
  };
  userId: string;
  refreshAuthPromise?: Promise<any>;
  siteOperatorLocalMenuGroupScopes: Array<string>;
  zendesk: ZendeskWidget;
  loggedInOnMobile: boolean;
  isPasswordReset: boolean;
}

export function useAuthStore(storeId = 'auth') {
  const store = defineStore(storeId, {
    state: (): State => ({
      intendedRoute: null,
      user: null,
      cdlUser: null,
      usedSSO: false,
      ssoRedirectError: false,
      cdlRealm: '6MNvqeNgGWSLAv4DoQr7CaKzaNGZl5',
      auth: null,
      userId: '',
      refreshAuthPromise: undefined,
      siteOperatorLocalMenuGroupScopes: [],
      zendesk: zendesk(),
      loggedInOnMobile: false,
      isPasswordReset: false,
    }),
    actions: {
      async handlOMSCodeCallback(authPayload: AuthPayload): Promise<void> {
        await this.authenticate(authPayload);
        if (authPayload.code) {
          this.usedSSO = true;
        }
        await this.getPermissions(this.userId);
      },
      async authenticate({ code, redirectPath = '/home' }: AuthPayload): Promise<void> {
        const {
          access,
          refresh,
          profile,
          oauthaccess: oauth,
          user: userId,
        } = await this.postUserOAuth({ redirectPath, code });
        this.setAuth({ userId, profile, auth: { access, refresh, oauth }, usedSSO: true });
      },
      async p2Authenticate(email: string, password: string) {
        const {
          user: userId,
          token,
          profile,
          access,
          refresh,
        } = await this.getUserAuth({ email, password });
        this.setAuth({ userId, profile, auth: { access, refresh }, usedSSO: false });
      },
      async postUserAuth({ refresh_token }): Promise<PostUserAuthResponse> {
        return this.cdlApi
          .post(`/user/auth?realm=${this.cdlRealm}`, { refresh_token })
          .then((r) => r.data);
      },
      async postUserOAuth({ redirectPath, code }): Promise<PostUserOauthResponse> {
        const isLocalhost = (url: string): boolean => {
          return url.includes('localhost') || url.includes('127.0.0.1');
        };

        const client_id = isLocalhost(window.location.origin)
          ? import.meta.env.VITE_CLIENT_ID_LOCAL
          : import.meta.env.VITE_CLIENT_ID;

        return this.cdlApi
          .post(`/user/oauth`, <AuthRequest>{
            code,
            oms_url: import.meta.env.VITE_SSO_AUTH_URL,
            callback_uri: `${window.location.origin}${
              redirectPath === '/' ? '/order' : redirectPath
            }`,
            client_id: client_id,
          })
          .then((r) => r.data);
      },
      async getUserAuth({ email, password }): Promise<GetUserAuthResponse> {
        return this.cdlApi
          .get(`/user/auth?realm=${this.cdlRealm}`, {
            auth: {
              username: email,
              password,
            },
          })
          .then((r) => r.data);
      },
      async sendUserForgotPassword({
        email,
      }: {
        email: string;
      }): Promise<PostUserResetPasswordResponse> {
        const response = await this.cdlApi.post(`/user/forgotpassword?realm=${this.cdlRealm}`, {
          email,
        });
        return response.data;
      },
      async resetPassword({
        userId,
        resetToken,
        newPassword,
        realm,
      }: {
        userId: string;
        resetToken: string;
        newPassword: string;
        realm: string;
      }): Promise<PostUserResetPasswordTokenResponse> {
        const response = await this.api.post(
          `/user/${userId}/resetpassword`,
          {
            password: newPassword,
            realm: realm,
          },
          {
            params: {
              reset_token: resetToken,
            },
          },
        );
        return response.data;
      },
      setAuth({
        auth,
        profile,
        userId,
        usedSSO,
      }: {
        auth: State['auth'];
        profile?: CDLUserProfile;
        userId?: string;
        usedSSO?: boolean;
      }) {
        const patchPayload: OptionalPick<State, 'auth' | 'usedSSO' | 'userId' | 'user'> = {
          auth,
        };
        if (profile) {
          patchPayload.user = {
            company: 'Compass Digital',
            firstName: profile.name?.first,
            lastName: profile.name?.last,
            telephone: profile.phone?.toString(),
            email: profile.email,
          };
        }
        if (userId !== undefined) {
          patchPayload.userId = userId;
        }
        if (usedSSO !== undefined) {
          patchPayload.usedSSO = usedSSO;
        }
        this.$patch(patchPayload);
      },
      logout() {
        // Logout from zendesk
        this.zendesk.zendeskLogoutUser();

        // Clear all the auth data
        this.$patch({
          auth: null,
          userId: null,
          user: null,
          cdlUser: null,
          usedSSO: false,
          siteOperatorLocalMenuGroupScopes: [],
        });

        // Persist is not cleaning everything, so we need to enforce that
        useScannerStore().$reset();
        localStorage.clear();
        sessionStorage.clear();
      },
      async getSiteToLocalMenuGroupPermissions(): Promise<void> {
        const siteOperatorIds = this.getScopeIds('site_operator_role', 'group');
        if (!siteOperatorIds?.length) {
          return;
        }

        const siteStore = useSiteStore();
        let siteOperatorLocalMenuGroupPermissions = [];

        try {
          const promises = siteOperatorIds.map((siteId: string) => {
            return siteStore.getSitesLocalMenuGroups(siteId);
          });
          const siteToLocalMenuGroupLinks = await Promise.all(promises);
          siteOperatorLocalMenuGroupPermissions =
            siteToLocalMenuGroupLinks?.flat().reduce((acc, val) => {
              acc.push(`read:local_menu_group:${val?.local_menu_group_id}`);
              acc.push(`write_nested:local_menu_group:${val?.local_menu_group_id}`);
              return acc;
            }, []) || [];
        } catch (error) {
          console.error(error);
        }

        this.$patch({ siteOperatorLocalMenuGroupScopes: siteOperatorLocalMenuGroupPermissions });
      },
      async getPermissions(id: RecordEntityId): Promise<void> {
        const response = await this.cdlApi.get<UserWithPermissions>(`user/${id}/permissions`);
        this.$patch({ cdlUser: response.data });

        await this.getSiteToLocalMenuGroupPermissions();
      },

      async getValidAccessToken(): Promise<string> {
        if (this.refreshAuthPromise) return this.refreshAuthPromise;
        this.refreshAuthPromise = this.getValidAccessTokenCall().finally(() => {
          this.refreshAuthPromise = undefined;
        });
        return this.refreshAuthPromise;
      },

      async getValidAccessTokenCall(): Promise<string> {
        const now = new Date(Date.now() + 30 * 1000);
        if (!this.auth?.access) return '';
        const access_expires = new Date(this.auth.access?.expires);
        const refresh_expires = new Date(this.auth.refresh?.expires);
        const oauth_expires = this.auth.oauth?.expires && new Date(this.auth?.oauth?.expires);
        if (!access_expires || !refresh_expires) return '';
        // if oauth is expired, even though cdl refresh might be valid, we still follow oauth expiry
        if (oauth_expires && oauth_expires < now) return '';
        if (access_expires > now) return this.auth.access?.token;
        // both access/refresh expired
        if (refresh_expires < now) return '';
        let refreshResponse: PostUserAuthResponse;
        try {
          refreshResponse = await this.postUserAuth({ refresh_token: this.auth.refresh.token });
        } catch (err) {
          console.error('Retrying auth after error:', err);
          refreshResponse = await this.postUserAuth({
            refresh_token: this.auth.refresh.token,
          }).catch((_err) => {
            console.error('Retry auth failed', _err.message);
            return {};
          });
        }
        if (!refreshResponse.access) {
          this.setAuth({
            auth: null,
          });
          return '';
        }
        this.setAuth({
          auth: {
            refresh: refreshResponse.refresh,
            access: refreshResponse.access,
            oauth: this.auth?.oauth,
          },
        });
        return refreshResponse.access.token;
      },
    },
    getters: {
      hasPermissions: (state: State) => {
        return (permissions: string[] = []): boolean => {
          try {
            const cdlUserPermissionsScopes = state?.cdlUser?.permissions?.scopes || [];
            const siteOperatorLocalMenuGroupScopes = state?.siteOperatorLocalMenuGroupScopes || [];

            const hasLimitedEditsRole = cdlUserPermissionsScopes.includes(
              `${MenuRoles.LOCAL_MENU_GROUP_LIMITED_EDITS}:menu_v3:role`,
            );

            // remove site operator based write access if user has 'limited edits' role
            const filteredSiteOperatorScopes = hasLimitedEditsRole
              ? siteOperatorLocalMenuGroupScopes.filter((element) => !element.includes('write'))
              : siteOperatorLocalMenuGroupScopes;

            return permissions.every((needed) => {
              const [required_action, required_service, required_reference] = needed.split(':');
              return [...cdlUserPermissionsScopes, ...filteredSiteOperatorScopes].some(
                (user_permission) => {
                  const [scope_action, scope_service, scope_reference] = user_permission.split(':');
                  return (
                    permissionMatch(scope_action, required_action) &&
                    permissionMatch(scope_service, required_service) &&
                    permissionMatch(scope_reference, required_reference)
                  );
                },
              );
            });
          } catch (err) {
            return false;
          }
        };
      },
      hasRole: (state: State) => {
        return (role: string, strict: boolean = false): boolean => {
          try {
            const scopes = state.cdlUser?.permissions?.scopes;

            if (isEmpty(scopes)) return false;

            if (includes(scopes, '*:*:*')) {
              if (strict && role === P2Role.ADMIN) {
                return true;
              }
              if (!strict) {
                return true;
              }
            }

            return scopes.some((scope) => scope.split(':')[0] === `${role}_role`);
          } catch (err) {
            return false;
          }
        };
      },
      getRole(state: State): P2Role | null {
        const permissions = state.cdlUser?.permissions;

        if (isEmpty(permissions)) return null;

        for (const scope of permissions.scopes) {
          const scopeRole = scope.split(':')[0].replace('_role', '') as P2Role;
          if (includes(Object.values(P2Role), scopeRole)) {
            return scopeRole;
          }
        }
        return null;
      },
      getUserAllowedResourceIds(state: State): string[] {
        const role = this.getRole as P2Role | null;
        if ([P2Role.ADMIN, P2Role.SYS_ADMIN].includes(role)) return ['*'];
        return (
          state.cdlUser?.permissions?.scopes
            .filter((scope) => {
              return (
                scope.includes(P2Role.SITE_OPERATOR) ||
                scope.includes(P2Role.IM_USER) ||
                scope.includes(P2Role.MENU_USER) ||
                scope.includes(P2Role.DC_TEAM) ||
                scope.includes(P2Role.RUNNER)
              );
            })
            .map((scopeId) => {
              return scopeId.split(':')[2];
            }) || []
        );
      },
      getScopeIds: (state: State) => {
        return (action: string, service: string): string[] => {
          const scopes = [
            ...(state.cdlUser?.permissions?.scopes || []),
            ...(state.siteOperatorLocalMenuGroupScopes || []),
          ];

          const filteredScopes = filter(scopes, (scope) => {
            const [scopeAction, scopeService, scopeId] = scope.split(':');
            return (
              (scopeService === service || scopeService === '*') &&
              scopeId !== '*' &&
              (scopeAction === action || scopeAction === '*')
            );
          });

          const scopeIds = map(filteredScopes, (scope) => {
            const parts = scope.split(':');
            return parts.length === 3 ? parts[2] : undefined;
          });

          return compact(scopeIds); // Removes any undefined values
        };
      },

      /**
       * Determines if the user is a site operator for a single site group.
       * This is based on the user's allowed resource IDs and checks if the first resource ID
       * belongs to a group type. If there is exactly one allowed resource and it is a group,
       * the method returns `true`.
       *
       * @param {State} state - The state object containing necessary information for the check.
       * @returns {boolean} `true` if the user has only one allowed resource and it is a group, otherwise `false`.
       */
      isSiteOperatorSingleSite(state: State): boolean {
        const allowedResourceIds = this.getSiteOperatorAllowedResourceIds as string[];
        const firstResourceId = allowedResourceIds[0];

        const shouldRedirectToFirstSite =
          allowedResourceIds.length === 1 && CDLID.contains(firstResourceId, { type: 'group' });

        return shouldRedirectToFirstSite;
      },

      /**
       * Retrieves the list of site operator allowed resource IDs that belong to a group type.
       * Filters the user's allowed resource IDs to include only those that are valid CDL group IDs.
       *
       * @param {State} state - The state object containing necessary information for the operation.
       * @returns {string[]} An array of resource IDs that are validated as belonging to a CDL group.
       */
      getSiteOperatorAllowedResourceIds(state: State): string[] {
        const allowedResourceIds = this.getUserAllowedResourceIds as string[];

        // Validate CDL IDs
        const validCDLIDs = allowedResourceIds.filter((id) =>
          CDLID.contains(id, { type: 'group' }),
        );

        return validCDLIDs;
      },
    },
    persist: {
      storage: localStorage,
      paths: ['auth', 'user', 'userId', 'cdlUser', 'usedSSO', 'siteOperatorLocalMenuGroupScopes'],
    },
    pagination: false,
  });
  return store();
}
