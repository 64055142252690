import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { SitesRouteNames } from '@centric-os/sites';
import { useCompassDigitalID } from '@centric-os/helpers';
import { useAuthStore, useSiteStore } from '@centric-os/stores';
import { P2Role } from '@centric-os/types';
import { storeToRefs } from 'pinia';
import { type Site } from '@centric-os/types';


/**
 * Navigation guard for the Announcements route.
 *
 * @param to - The target route being navigated to.
 * @param from - The current route being navigated away from.
 * @param next - The function to resolve the navigation.
 */
export const beforeEnter = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  try {
    const authStore = useAuthStore();
    const siteOperatorIds = authStore.getScopeIds('site_operator_role', 'group');
    const siteOperatorBrandIds = authStore.getScopeIds('site_operator_role', 'brand');
    const IMRoleIds = authStore.getScopeIds('im_user_role', 'group');
    const IMRoleBrandIds = authStore.getScopeIds('im_user_role', 'brand');

    const { valid } = useCompassDigitalID();
    const siteID: string = to.params.siteId as string;

    const siteStore = useSiteStore();
    const { site } = storeToRefs(siteStore);
    if (!site.value || site.value?.id !== siteID) {
      await siteStore.getSite(siteID);
    }

    if (valid(siteID) 
	&& (authStore.hasRole(P2Role.ADMIN) 
	    || siteOperatorIds.includes(siteID) 
	    || IMRoleIds.includes(siteID) 
	    || areBrandsInSite(IMRoleBrandIds, site.value) 
	    || areBrandsInSite(siteOperatorBrandIds, site.value))) {
      return next();
    } else {
      return next({
        name: SitesRouteNames.SITES_LIST,
        replace: true,
      });
    }

  } catch (error) {
    console.error('Error during navigation guard execution:', error);
    return next({
      name: SitesRouteNames.SITES_LIST,
      replace: true,
    });
  }
};

/*
 * Return true if at least one brand id is found in site
 */
function areBrandsInSite(brandIDs: string[], site: Site): boolean {
  return site.locations.some(location => location.brands.some(brand => brandIDs.includes(brand.id)));
}
