import type { Address } from '../address';
import type { LocalMenuGroup } from '../menu';
import type { IntegrationPartner } from '../cd-config';
import type { StationBrand } from '../station';
import { SortOrder, type PaginationConfig, type RecordEntityId } from './../records/index';

export interface Site {
  // group
  id?: RecordEntityId;
  name?: string;
  label?: {
    en?: string;
    fr?: string;
  };
  // Distance in meters from the group
  distance?: number;
  locations?: SiteLocation[];
  address?: Address;
  meta?: {
    [index: string]: any;
  };
  style?: any;
  [index: string]: any;

  // new site properties from edge API
  location_group?: any[];
  app?: string;
  sector?: string;
  unit_number?: string;
  visible_stations?: number;
  menu_sources?: SiteMenuSources[];
  local_menu_group?: Partial<LocalMenuGroup>[];
}

export enum SiteMenuSources {
  CENTRIC = 'centric',
  ADMIN = 'admin',
}

export interface SiteLocation {
  // location
  id: string;
  operation_id?: number;
  name?: string;
  label?: {
    en?: string;
    fr?: string;
  };
  search?: string[];
  app?: string;
  address?: Address;
  latitude?: number;
  longitude?: number;
  brands?: StationBrand[];
  meta?: {
    [index: string]: any;
    app_name: string;
    unit: number;
    unit_id: number;
  };
  phone?: string;
  // group
  location_group?: string;
  // multigroup
  location_multigroup?: string;
  // sector
  sector?: string;
  date?: {
    deleted?: string;
    created?: string;
    modified?: string;
  };
  market_place?: {
    label?: {
      en?: string;
      fr?: string;
    };
    location_description?: {
      en?: string;
      fr?: string;
    };
    logo?: string | File;
    is?: {
      enabled?: boolean;
      pickup_supported?: boolean;
      delivery_supported?: boolean;
    };
    delivery_details?: {
      show_single_timeslot?: boolean;
      show_instructions?: boolean;
      runner_app_enabled?: boolean;
    };
    delivery_destinations?: string[];
    pickup_instruction?: {
      en?: string;
      fr?: string;
    };
    hours?: any;
    deliveryHours?: any;
    service_fee?: {
      type?: string;
      value?: number;
    };
    [index: string]: any;
  };
  [index: string]: any;
}

export interface BusinessUnitsKDS {
  units: KDSUnit[];
}

export interface KDSUnit {
  kdsDevices: KDSDevice[];
  unitNumber: string;
}

export interface KDSDevice {
  id: number;
  name: string;
  serialNumber: string;
  brands: string[];
}

export interface KDSDeviceWithUnit extends KDSDevice {
  unitNumber: string;
}

export enum KdsVendor {
  COMPASS_KDS = 'cdl',
  NEXTEP = 'nextep',
  VOLANTE = 'volante',
  AGILYSYS = 'agilysys',
}
export interface Device {
  device_type: DeviceType;
  site_device_id: RecordEntityId;
  business_units?: RecordEntityId[];
  name?: string;
  vendor?: IntegrationPartner | string;
}

export type DeviceTypeVendorMapping = {
  [key in DeviceType]?: DeviceMapping;
};

export type DeviceMapping = {
  [key in IntegrationPartner]?: Device[];
} & Record<string, Device[]>;

export type SiteDevices = {
  site_devices: DeviceMapping;
};

export interface NextepDevice {
  device_type: DeviceType;
  site_device_id: RecordEntityId;
  business_units?: RecordEntityId[];
}

export enum DeviceType {
  KIOSK = 'KIOSK',
  POS = 'POS',
  OMS = 'OMS',
  DMD = 'DMD',
}

export interface DeviceArrays {
  [DeviceType.KIOSK]?: Device[];
  [DeviceType.POS]?: Device[];
  [DeviceType.OMS]?: Device[];
  [DeviceType.DMD]?: Device[];
}

export interface NextepDevice {
  Type: DeviceType;
  Id: RecordEntityId;
  RevenueCenter: string;
}

export interface FileUpload {
  file_base64?: string;
  url?: string;
}

export enum StationType {
  V2 = 'Admin Menu',
  CENTRIC_OS = 'Centric Menu',
}

export interface StructuredStationEvents {
  menuEvents?: StructuredMenuEvents;
  businessEvents?: CalendarEvent[];
  closureEvents?: CalendarEvent[];
  deliveryEvents?: CalendarEvent[];
}

export interface StationClosureFormFields {
  reason: string;
  startDateTime: Date;
  endDateTime: Date;
  delete?: boolean;
}

export interface StructuredMenuEvents {
  [key: string]: CalendarEvent[];
}

export interface StationEvents {
  [key: string]: StructuredStationEvents;
}

export interface CalendarObject {
  date: CalendarDate;
  events: CalendarEvent[];
  id: string;
  timezone: string;
}

export interface CalendarEvent {
  data: CalendarEventData;
  schedule: CalendarEventSchedule;
}

export interface CalendarEventData {
  calendar: CalendarTypes;
  color?: string;
  id: string;
  title: string;
  is?: CalendarEventIs;
  menu?: string;
  version: number;
}

export interface CalendarEventIs {
  delivery?: boolean;
  pickup?: boolean;
  frictionless?: boolean;
  hidden?: boolean;
  disabled?: boolean;
}
export interface CalendarEventSchedule {
  dayOfWeek?: number[];
  duration?: number;
  start?: number;
  end?: number;
  every?: { week: number } | boolean;
  year?: number[];
  month?: number[];
  dayOfMonth?: number[];
  times?: string[];
  durationUnit?: string;
}
export interface CalendarDate {
  last_sync_error: number;
  last_sync: number;
}

export const enum CalendarTypes {
  MENU = 'menu',
  BUSINESS = 'business',
  HOLIDAY = 'holiday',
  DELIVERY = 'delivery',
}
export const TypeOfKds = {
  nextep: {
    name: 'Nextep',
    value: 'nextep',
  },
  agilysys: {
    name: 'Agilysys',
    value: 'agilysys',
  },
  volante: {
    name: 'Volanté',
    value: 'volante',
  },
  cdl: {
    name: 'Centric KDS',
    value: 'cdl',
  },
};

export interface ReportingConfig {
  location_group_id: RecordEntityId;
  day_time: Date | string;
  day_parts: DayParts[];
  is_default: boolean;
}

export interface DayParts {
  label: string;
  start: Date | string;
  end: Date | string;
}

export const enum OrderType {
  PRE_ORDER = 'Pre-Order',
  SCAN_AND_PAY = 'Scan & Pay',
  JWO = 'JWO',
}

export const enum JDE_ENVIRONMENTS {
  PRODUCTION = 'Production',
  PY = 'PY',
  PY_AND_PRODUCTION = 'PY & Production',
}

export const Sectors = {
  BON_APPETIT: 'Bon Appetit',
  CANTEEN: 'Canteen',
  CHARTWELLS: 'Chartwells',
  CULINART: 'CulinArt',
  EUREST: 'Eurest',
  FLIK: 'FLIK',
  MORRISON: 'Morrison',
  RESTAURANT_ASSOCIATES: 'Restaurant Associates',
  WOLFGANG_PUCK: 'Wolfgang Puck',
};

export const enum MULTIGROUPS {
  BOOST = 'Boost',
  THRIVE = 'Thrive',
  TACIT = 'Tacit',
}

export const defaultSitePaginationConfig: PaginationConfig = {
  queryParams: {
    limit: 25,
    page: 1,
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  },
  metaParams: {
    resultCount: 0,
    totalPages: 1,
    totalResults: 0,
  },
};

export interface Group {
  id?: RecordEntityId;
  name?: string;
}
export interface Multigroup {
  id?: RecordEntityId;
  groups?: Group[];
}
